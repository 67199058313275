ion-content.live-bg {
  /* --background: url('/public/assets/fondoa.svg') #026dce; */
  --background: #026dce;
  /* --background: url('/public/assets/bg-blue.png'); */

  background-repeat: no-repeat;
  background-size: cover;
}

ion-content.live-bg::part(background) {
  background: url('/public/assets/bg-blue.png') no-repeat center center #026dce;
  background-repeat: no-repeat;
  background-size: cover;
}
ion-content.live-red-bg::part(background) {
  /* --background: url('/public/assets/fondoa.svg') #dd1956; */
  --background: url('/public/assets/fondoGorria.png') no-repeat center center
    #dd1956;
  /* background-repeat: no-repeat; */
  background-size: cover;
  /* transition: 1s ease-in; */
}

ion-content.live-green-bg::part(background) {
  /* --background: url('/public/assets/fondoa.svg') #dd1956; */
  --background: url('/public/assets/fondoBerdea.png') no-repeat center center
    #2d821a;
  /* background-repeat: no-repeat; */
  background-size: cover;
  /* transition: 1s ease-in; */
}
ion-content.live-bg *,
ion-content.live-red-bg *,
ion-content.live-green-bg * {
  color: white;
}

ion-content .center-container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 0 1rem;
  margin: 0 20px;
  /* background: url('/public/assets/bg-blue.png') #026dce; */

  /* backdrop-filter: blur(15px);
  border: 1px solid #f5f5f5c5; */
  /* border-radius: 12px; */
  /* border: 1px solid radial-gradient(189.74% 101.94% at 0% 46.95%, rgba(39, 53, 122, 0.75) 0%, rgba(39, 53, 122, 0) 100%);
  border-radius: 5px; */
}

ion-content .countdown {
  font-size: 120px;
  font-weight: 900;
}

ion-content .active-badge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: var(--ion-color-secondary);
}
ion-content .non-active-badge {
  background: transparent;
}

.question-container {
  position: relative;
}
.question-container div {
  color: var(--ion-color-secondary) !important;
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
  background: linear-gradient(
    94.98deg,
    rgb(189 188 188) 1.3%,
    #ffffff 55.96%,
    rgb(189 188 188) 98.47%
  );
  margin: 0px 15px;
  min-height: 41px;
  border: 2px solid white;
  font-weight: 700;
}
.question-container.gold div {
  background: linear-gradient(
    94.98deg,
    #fefc71 1.3%,
    #f0cc45 55.96%,
    #fefc71 98.47%
  );
}
.user-anwser-row {
  background: linear-gradient(
    90deg,
    transparent 0%,
    transparent 8%,
    #979ebf 8%,
    #979ebf 92%,
    transparent 92%,
    transparent 100%
  );
  /* background: #979EBF; */
  align-items: center;
}
.user-anwser-row ion-col {
  padding: 0px;
  text-align: center;
}

.user-anwser-row .answer-button {
  width: 42px;
  height: 42px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.user-anwser-row .answer-button > div {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.user-anwser-row .answer-button.dark {
  --background: #010d37;
  font-weight: 700;
}
.user-anwser-row .answer-button.light {
  --background: var(--ion-color-light);
  --color: var(--ion-color-secondary);
  font-weight: 700;
}

.user-anwser-row .answer-button.light > div {
  color: var(--ion-color-secondary);
  font-weight: 900;
}
.user-anwser-row .question-button {
  height: auto;
  width: 90%;
  margin: auto;
}

.scrolling-box {
  height: 70%;
  overflow-y: auto;
}

.sticky-top {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 2;
  backdrop-filter: blur(15px);
  /* border-bottom: 1px solid #f5f5f5c5; */
}
.sticky-top.ios-device {
  padding-top: 7vh;
}

h2.live-msg {
  font-weight: 400;
}
.show-user-selection-button {
  border: 1px solid #ffffff;
  border-radius: 8px;
  margin: 20px 0px;
}
.show-user-selection-button p {
  margin: 8px;
}

.show-user-selection-button p span {
  text-decoration: underline;
  font-weight: bold;
}

.resolution-button {
  height: 120px;
  /* padding: 12px 15px; */
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 16px;
  text-align: center;
  /* font-weight: bold; */
  font-weight: 700;
  display: flex;
  align-content: stretch;
  flex-wrap: wrap;
  /* gap: 10px; */
}
.resolution-button p {
  margin: 5px;
  font-size: 14px;
  text-transform: uppercase;
}
.resolution-button h3 {
  font-size: 26px;
  margin: 5px;
}
.resolution-button.bg-red {
  --background: linear-gradient(0deg, #dd1956, #e44576);
  /* linear-gradient(0deg, #DD1956, #DD1956), linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 50.52%);  */
}

.resolution-button.bg-gold {
  --background: linear-gradient(0deg, #eec33c, #fcf56a);
}
.resolution-button.bg-gold > * {
  color: var(--ion-color-secondary);
}
