.mhlz-button {
  --color: var(--ion-color-light);
  --background: var(--ion-color-danger);
  --border-radius: 16px;
  --box-shadow: 0px 4px 4px 0px #00000040;
  text-transform: none;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.4px;
}
.mhlz-button ion-spinner {
  width: 20px;
  height: 20px;
}
