.number-input-container {
  /* This bit sets up the horizontal layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.number-input {
  min-height: 50px;
  max-width: 50px;
  background: transparent;
  flex-grow: 6;
  border: none;
  text-align: center;

  /* I've used padding so you can see the edges of the elements. */
  padding: 1px;
  border: 1px solid var(--ion-color-step-300, #b3b3b3);
  border-radius: 4px;
  margin: 0 2px;
}

/* remove the input focus blue box, it will be in the wrong place. */
.number-input:focus {
  border: 2px solid var(--ion-color-primary);
  outline: none;
}
