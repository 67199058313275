:root {
  /* --ion-font-family: 'Archivo', sans-serif; */
  --ion-font-family: 'Archivo Variable', sans-serif;
  --ion-text-color: var(--ion-color-secondary);
}

ion-tab-bar {
  --border: 2px solid var(--ion-color-primary);
}

ion-tab-bar ion-tab-button ion-icon,
ion-tab-bar ion-tab-button ion-label {
  color: var(--ion-color-primary);
}

ion-tab-bar ion-tab-button.tab-selected ion-icon {
  animation: rubberBand 0.8s;
}

h1 {
  font-weight: 700;
  color: var(--ion-color-secondary);
}
h3 {
  font-size: 20px;
}

h1,
h2,
h3 {
  color: var(--ion-color-secondary);
  font-weight: 700;
}
h2,
p,
strong {
  color: var(--ion-color-secondary);
  letter-spacing: 0.4px;
}
p {
  line-height: 140%;
}

ion-refresher {
  /* top: -60px; */
  z-index: 11 !important;
}

ion-modal.full-modal {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.ionicon {
  color: var(--ion-color-secondary) !important;
}

h3.question-type {
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: 800;
  letter-spacing: 0.5px;
}

.question-type.ion-margin-top {
  margin-top: 3rem;
}
