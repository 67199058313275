.home-content,
.home-content-week {
  padding: 0px 10px;
}

.home-content h3,
.home-content h2,
.home-content-week h3,
.home-content-week h2 {
  color: var(--ion-color-secondary);
}

/* .home-content h2,
.home-content-week h2 {
  font-weight: 900;
  font-size: 28px;
} */

.home-content p.extra-text,
.home-content strong,
.home-content-week p.extra-text,
.home-content-week strong {
  color: var(--ion-color-danger);
}

.home-content-week {
  background: #f7f7f7;
  border-radius: 8px;
  padding: 20px;
  /* margin: 40px 20px; */
  margin: 20px 20px 40px 20px;
}

.home-content p {
  padding: 0 1rem;
}

.today-contestants {
  background: rgb(224, 27, 93);
  background: linear-gradient(
    90deg,
    rgba(224, 27, 93, 1) 0%,
    rgba(237, 37, 126, 1) 50%,
    rgba(224, 27, 93, 1) 100%
  );
  min-height: 225px;
  box-shadow: none;
}

.today-contestants * {
  color: white;
}

.today-contestants img {
  height: 132px;
  width: auto;
  object-fit: cover;
  border: 2px solid white;
  border-radius: 4px;
}

.today-contestants ion-card-title {
  font-size: 14px;
  font-weight: 700;
  margin-top: 16px;
}

.today-contestants ion-card-subtitle {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 8px;
}
