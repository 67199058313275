.phone-input-container {
  /* This bit sets up the horizontal layout */
  display: flex;
  flex-direction: row;

  /* This bit draws the box around it */
  border: 1px solid grey;

  /* I've used padding so you can see the edges of the elements. */
  padding: 1px;
  border: 1px solid var(--ion-color-step-300, #b3b3b3);
  border-radius: 4px;
}
.phone-input {
  min-height: 56px;
  background: transparent;
  width: 100%;
  flex-grow: 2;
  border: none;
}

/* remove the input focus blue box, it will be in the wrong place. */
.phone-input:focus {
  outline: none;
}
