.user-item {
  /* --padding-end: 10px; */
  --padding-start: 10px;
  /* --inner-padding-end: 0px; */
  --background: #f7f7f7;
  --border-radius: 5px;
  /* --margin-bottom: 5px; */
  margin-bottom: 5px;
  --color: var(--ion-color-secondary);
  position: relative;
}
.user-item ion-avatar {
  background-color: var(--ion-color-light);
  width: 32px;
  height: 32px;
  object-fit: cover;
}
.user-item ion-label {
  font-size: 16px;
  font-weight: 600;
  /*letter-spacing: 0.5px;*/
}
.user-item ion-label p {
  position: sticky;
  color: white;
  z-index: 1;
  margin-top: 2px;
}

.user-item #user-ellipse {
  position: absolute;
  top: -60px;
  left: 54vw;
  width: 120%;
  height: 170px;
  border-radius: 50% 0 0 50%;
  background: linear-gradient(90deg, #27357a 2.49%, #026dce 52.74%),
    linear-gradient(0deg, #27357a, #27357a);
  z-index: 0;
}

.sc-ion-label-md-h.sc-ion-label-md-s.md {
  margin: 0;
}

.user-item #user-ellipse.large-height {
  /* height: 200px;
  top: -67px;
  left: 48vw;*/
}
