.in-item {
  max-width: 100%;
}

.privacy-policy-text strong {
  /* text-decoration: underline; */
  color: white;
}
ion-content.login-blue-bg .input-padding {
  padding-top: 10px;
}

ion-content.login-blue-bg ion-checkbox {
  --size: 28px;
  --checkbox-background: transparent;
}

ion-content.login-blue-bg ion-checkbox::part(container) {
  border-radius: 4px;
  border: 1px solid white;
  padding: 4px;
}

ion-content.login-blue-bg {
  /* background: url('/public/assets/fondoa.svg') #026dce; */
  background: #026dce;
  background-repeat: no-repeat;
  background-size: cover;
  --background: transparent;
}

ion-content.login-blue-bg > * {
  color: white;
}
ion-content.login-blue-bg > ion-item {
  --background: transparent;
}
.login-blue-bg .input-fill-outline.sc-ion-input-md-h {
  --border-color: white !important;
  --placeholder-color: white !important;
  --highlight-color-focused: white !important;
  --color: white !important;
}

.login-blue-bg .phone-input-container ion-button#open-phone-prefixes-modal {
  color: white;
}
.login-blue-bg .phone-input-container,
.login-blue-bg .number-input {
  border-color: white;
}
.login-blue-bg .phone-input-container input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
}
