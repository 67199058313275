.header-profile-item ion-avatar {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-light);
  border: 1px solid var(--ion-color-light);

  /*
  Beheko ion-item-ari padding-start kenduko diogu
  eta ion-avatarari itemaren margina aplikatuko diogu (16px).

  Horrela ion-titleak alderik aldeko zabalera hartuko du.
  */
  margin-left: 16px;
}

.header-profile-item ion-item {
  --padding-end: 0px;
  --inner-padding-end: 0px;
  --padding-start: 0px;
}
