.group-detail {
  --padding-end: 0px;
  --padding-start: 0px;
  --inner-padding-end: 0px;
}

.group-detail ion-thumbnail {
  --size: 36px;
  --border-radius: 3px;
}

ion-segment.sticky-segment {
  position: sticky;
  top: 0px;
  z-index: 3;
  margin: 0px 10px;
}

ion-segment-button {
  --indicator-color: white;
  --color: white;
  --color-checked: var(--ion-color-secondary);
}

ion-content.blue-bg {
  --background: #026dce;
}

.group-settings-modal {
  top: 60px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
  border-radius: 30px;
}
.group-settings-modal.ios-device {
  top: calc(60px + 7vh) !important;
}
