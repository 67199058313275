.ion-page.blue-bg {
  background: #026dce;
  --ion-toolbar-background: transparent;
}
.ion-page.blue-bg ion-content,
.ion-page.blue-bg ion-header ion-toolbar ion-item,
.ion-page.blue-bg ion-header ion-toolbar {
  --background: transparent;
  --color: white;
}
.ion-page.blue-bg .blue-bg-container {
  position: relative;
  height: 100%;
}

.ion-page.blue-bg .blue-bg-container #ellipse {
  background-color: white;
  position: absolute;
  top: -40px;
  left: -40px;
  width: 120%;
  height: 140px;
  border-radius: 50% 50% 0 0;
  z-index: 1;
}

.ion-page.blue-bg .blue-bg-content {
  /* height: 100%;*/
  min-height: calc(100vh - 114px); /*Full height - (toolbar+tabs)*/
  background: white;
  color: var(--ion-color-secondary);
  position: relative;
  z-index: 2;
}
.ellipsed-top-margin {
  margin-top: 40px;
}
