.mhlz-game-button {
  --color: var(--ion-color-light);
  border-radius: 6px;
  --box-shadow: 0px 4px 4px 0px #00000040;
  text-transform: none;
  --background: linear-gradient(0deg, #050985, #2242b5);
  border: 2px solid white;
  letter-spacing: 0;
  font-size: 20px;
  height: auto;
  min-height: 50px;
  --padding-start: 4px;
  --padding-end: 4px;
  --padding-top: 0;
  --padding-bottom: 0;
}
.mhlz-game-button.small {
  width: 60%;
}

.mhlz-game-button.color-success {
  --background: linear-gradient(0deg, #43a628, #57714f);
}

.mhlz-game-button.color-danger {
  --background: linear-gradient(0deg, #d12020, #b44949);
}

.mhlz-game-button.selected {
  --background: linear-gradient(0deg, #fefc71, #f0cc45);
  color: var(--ion-color-secondary);
}
.mhlz-game-button.button-disabled {
  opacity: 1;
  filter: brightness(0.75);
}
