.centered-header-title :is(ion-button, ion-avatar) {
  z-index: 100;
}

.centered-header-title ion-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}

ion-header ion-item {
  --inner-padding-start: 0px;
  --padding-start: 0px;
}
