.photo-editor img {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  margin-top: 20px;
  object-fit: cover;
}

.photo-editor img.group-img {
  border-radius: 10%;
}

.photo-editor {
  position: relative;
}

.photo-editor .bottom-right {
  position: absolute;
  bottom: 1.5rem;
  right: calc(50% - 4rem);
}

.photo-editor .small-fab {
  width: 36px;
  height: 36px;
}
