swiper-slide {
  min-height: 60vh;
}

swiper-container::part(bullet-active) {
  background-color: #fff;
}

ion-content.welcome-page {
  /* background: url('/public/assets/fondoa.svg') #026dce; */
  background: #026dce;
  background-repeat: no-repeat;
  background-size: cover;
  --background: transparent;
  text-align: center;
}

ion-content.welcome-page h1,
ion-content.welcome-page h2,
ion-content.welcome-page h3,
ion-content.welcome-page h4,
ion-content.welcome-page p {
  color: white;
}

.welcome-page-close-button {
  padding-top: 10vh;
}

.welcome-page .modal-close-button {
  --background: none;
  --color: #fff;
  --box-shadow: none;
}
