.birthdate-input-container {
  /* This bit sets up the horizontal layout */
  display: flex;
  flex-direction: row;

  /* This bit draws the box around it */
  /* border: 1px solid var(--ion-color-secondary); */

  /* I've used padding so you can see the edges of the elements. */
  padding: 1px;
  /* border: 1px solid var(--ion-color-step-300, #b3b3b3); */
  border: 1px solid var(--ion-color-secondary) !important;

  border-radius: 4px;
  position: relative;
}

.birthdate-input::placeholder {
  color: var(--ion-color-secondary);
  opacity: 1; /* Firefox */
}

.birthdate-input {
  min-height: 56px;
  background: transparent;
  width: 100%;
  flex-grow: 2;
  border: none;
  padding: 16px;
  color: var(--ion-color-secondary);
}

/* Span */
.birthdate-input-container > span {
  position: absolute;
  top: -8px;
  left: 12px;
  background: white;
  font-size: 13px;
  padding: 0px 4px;
  color: var(--ion-color-secondary);
}
