.notification-item {
  background: #f7f7f7;
  border-radius: 8px;
  padding: 20px;
}
.notification-item h2,
.notification-item p {
  margin: 0;
}
.notification-item p {
  margin-top: 10px;
}
