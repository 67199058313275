.game-item {
  background: #f7f7f7;
  flex-wrap: wrap;
  display: flex;
  height: 83px;
  border-radius: 8px;
  position: relative;
  /* padding-left: 20px; */
}
.game-item ion-row {
  height: 83px;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
}

#ellipse-1 {
  position: absolute;
  top: -60px;
  left: 0;
  width: 120%;
  height: 170px;
  border-radius: 50% 0 0 50%;
  background: linear-gradient(0deg, #026dce, #026dce),
    linear-gradient(90deg, #27357a 2.49%, #026dce 52.74%),
    linear-gradient(0deg, #27357a, #27357a);
}

#ellipse-2 {
  position: absolute;
  top: -70px;
  left: 0;
  width: 120%;
  height: 170px;
  border-radius: 50% 0 0 50%;
  background: linear-gradient(90deg, #27357a 2.49%, #026dce 52.74%),
    linear-gradient(0deg, #27357a, #27357a);
}

.ellipse-container {
  position: relative;
  text-align: center;
}

.ellipse-container > * {
  margin: 0;
  color: white;
}

.game-item strong {
  font-weight: 700;
  font-size: 18px;
}
.ellipse-container h3 {
  font-weight: 700;
  font-size: 24px;
}
.ellipse-container {
  font-weight: 700;
  font-size: 18px;
}

.game-item .padding-bottom {
  padding-bottom: 5px;
}
