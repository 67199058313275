.group-item {
  --padding-end: 0px;
  --padding-start: 0px;
  --inner-padding-end: 0px;
}
.group-item ion-thumbnail {
  --size: 36px;
  --border-radius: 3px;
}
.group-item ion-label {
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.5px;
}
.group-item p {
  font-size: 12px;
  font-weight: 400;
  color: var(--ion-color-secondary);
  /* line-height: 15px; */
}
