ion-modal.bottom-modal {
  /* --width: 90%; */
  --min-width: 250px;
  /* --height: fit-content; */
  --height: 200px;
  --border-radius: 8px 8px 0px 0px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal.bottom-modal::part(content) {
  position: absolute;
  bottom: 0;
}
ion-modal.bottom.modal > .modal-shadow {
  display: none !important;
}

.new-group-modal-button {
  width: 100%;
  /* height: 100%; */
  height: 100px;
  --background: #026dce;
  /* url('/public/assets/fondoa.svg') ; */
}

.new-group-modal-button ion-col {
  padding: 0;
}
ion-button.new-group-modal-button.button-1 {
  padding-inline-end: 10px;
}
ion-button.new-group-modal-button.button-2 {
  padding-inline-start: 10px;
}
ion-button.new-group-modal-button div {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

ion-button.new-group-modal-button div {
  font-size: 1.1rem;
}
ion-button.new-group-modal-button div ion-icon {
  font-size: 50px;
}
.add-group-modal-fab {
  position: fixed;
}
